'use client';

import Image from 'next/image';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import Link from 'next/link';
import useIsMobile from '~libs/use-is-mobile';
import AuthorizedNavigation from '~components/layouts/navbar/authorized-navigation';
import ChangeLanguageMenu from '~components/widgets/change-language';
import UnauthorizedNavigation from '~components/layouts/navbar/unauthorized-navigation';
import PageUrls from '~constants/page-urls';
import { S3_BUCKET_IMAGES } from '~constants/etc';
import { useUserInfo } from '~hooks/use-user';

const TopBar: FC = () => {
  const isMobile = useIsMobile();
  const { data: userInfo } = useUserInfo();
  const [initialRenderComplete, setInitialRenderComplete] = useState(true);

  useEffect(() => {
    setInitialRenderComplete(true);
  }, []);

  if (!initialRenderComplete) return null;

  if (isMobile) {
    return (
      <div className="bg-layout flex min-h-[34px] w-full items-center justify-between gap-4 p-4">
        <Link href={PageUrls.Home}>
          <Image
            alt="logo"
            height={34}
            priority
            src={`${S3_BUCKET_IMAGES}/logo.webp`}
            width={60}
          />
        </Link>

        {userInfo ? (
          <AuthorizedNavigation />
        ) : (
          <UnauthorizedNavigation className="my-2" />
        )}
      </div>
    );
  }

  return (
    <div className="bg-layout mx-auto flex h-[72px] max-w-[1920px] items-center justify-between md:px-12 xl:px-[100px]">
      <Link href={PageUrls.Home}>
        <Image
          alt="logo"
          height={41}
          priority
          src={`${S3_BUCKET_IMAGES}/logo.webp`}
          width={72}
        />
      </Link>
      <div className="flex items-center gap-2">
        {userInfo ? <AuthorizedNavigation /> : <UnauthorizedNavigation />}
        <ChangeLanguageMenu />
      </div>
    </div>
  );
};

export default TopBar;
