import type { GameFeatureListsResApi, ProviderInterface } from 'types/provider';
import type { Method } from 'axios';
import type { Config, Return } from './use-request';
import useRequest from './use-request';

export const useProviders = (): Return<ProviderInterface, unknown> => {
  const { data, ...rest } = useRequest<ProviderInterface>({
    url: '/providers',
  });
  return {
    data,
    ...rest,
  };
};

export const useGameFeature = (
  currency: string,
  config?: Config<GameFeatureListsResApi[]>,
) => {
  return useRequest(
    {
      method: 'GET' as Method,
      url: '/providers/game-features',
      params: {
        currency,
      },
    },
    {
      revalidateOnFocus: true,
      ...config,
    },
  );
};
