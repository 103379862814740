'use client';

import type { FC, ReactNode } from 'react';
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HomeFilled,
  SportFilled,
  SlotFilled,
  CasinoFilled,
  AffiliateFilled,
  PromotionFilled,
  CustomerServiceFilled,
} from '@packages/icons-react';
import { cn } from 'class-merge';
import { parseCookies } from 'nookies';
import { delay } from 'lodash';
import { ProviderEnum } from '~constants/menus';
import ProviderCard from '~components/card/provider-card';
import PageUrls from '~constants/page-urls';
import { useAllGames } from '~hooks/use-gaming';
import { useCasinos } from '~hooks/use-casinos';
import { useSportsbook } from '~hooks/use-sports-book';
import { useRouter } from '~hooks/use-router';
import {
  DEFAULT_CURRENCY,
  ProviderTypeEnum,
  S3_BUCKET_IMAGES,
  S3_MEDIA_BUCKET,
} from '~constants/etc';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';
import { ClientSideRender } from '~components/layouts/client-side-render';
import { ProviderCasinoResApiInterface } from 'types/casino';
import objectToQueryString from '~libs/object-to-query-string';
import HttpErrorHandler from '~components/http-error-handler';
import { encrypt } from '~libs/encrypt';
import request from '~libs/request';
import { ProviderSportResApiInterface } from 'types/sport';
import { onOpenSportURL } from '~libs/open-url';

const MenuBar: FC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );

  const [menuEnter, setMenuEnter] = useState<string | any>();
  const [menuActive, setMenuActive] = useState<string>('');
  const [subMenuActive, setSubMenuActive] = useState(false);
  const currentPathname =
    typeof window === 'object' ? window.location.pathname : '';
  const activePathName = currentPathname.replace(/^\/[a-z]{2}(\/|$)/, `$1`);
  const cookies = parseCookies();
  const locale = cookies.NEXT_LOCALE || '';
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;
  const { data: casinos } = useCasinos(currency);
  const { data: gaming } = useAllGames(currency);
  const { data: sports } = useSportsbook(currency);

  const menuData: {
    title: string;
    icon: ReactNode;
    action?: string;
    path?: string;
    key: string;
    subMenu: boolean;
    disabled?: boolean;
    isNew?: boolean;
  }[] = [
    {
      title: t('common:menu.home'),
      icon: <HomeFilled />,
      action: PageUrls.Home,
      path: '',
      key: 'home',
      subMenu: false,
    },
    {
      title: t('common:menu.sportsbook'),
      icon: <SportFilled />,
      key: ProviderEnum.SPORT,
      subMenu: true,
    },
    {
      title: t('common:menu.casino'),
      icon: <CasinoFilled />,
      key: ProviderEnum.CASINO,
      subMenu: true,
    },
    {
      title: t('common:menu.gaming'),
      icon: <SlotFilled />,
      key: ProviderEnum.GAMING,
      subMenu: true,
    },
    {
      title: t('common:menu.affiliate'),
      icon: <AffiliateFilled />,
      action: PageUrls.Affiliate,
      path: '/affiliate',
      key: 'affiliate',
      subMenu: false,
    },
    {
      title: t('common:menu.promotion'),
      icon: <PromotionFilled />,
      action: PageUrls.FinancialDepositPromotion,
      path: '/deposit/promotion',
      key: 'promotion',
      subMenu: false,
    },
    {
      title: t('common:menu.customer_service'),
      icon: <CustomerServiceFilled />,
      action: PageUrls.Contact,
      path: '/contact',
      key: 'customer-service',
      subMenu: false,
    },
  ];

  useEffect(() => {
    const activeMenu =
      menuData.find((ap) => ap.path === activePathName)?.path ?? '';
    setMenuActive(activeMenu);
  }, [activePathName]);

  const onRequestOpenCasino = (
    provider: ProviderCasinoResApiInterface,
    game: any,
  ): void => {
    if (!userInfo) {
      return router.push(PageUrls.Login);
    }
    try {
      const currencyFiltered = provider.currencies
        .filter((x) => x.includes(currency))
        .at(0);
      const queryString = objectToQueryString({
        category: ProviderTypeEnum.Casino,
        providerId: provider.objectId,
        providerName: provider.id,
        gameCode: game.gameCode,
        currency: currencyFiltered,
        walletId: walletInfo?.objectId,
      });
      router.push(`/${locale}${PageUrls.OpenGame}?qs=${encrypt(queryString)}`);
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  const handleOpenCasino = async (provider: ProviderCasinoResApiInterface) => {
    let game;
    try {
      game = await request({
        url: `/casinos/${provider.id}/games`,
        method: 'GET',
        params: {
          currency,
        },
      });
    } catch (e) {
      HttpErrorHandler(e);
    }
    if (game.length === 1) {
      onRequestOpenCasino(provider, game[0]);
    } else {
      router.push(`${PageUrls.Casino}/${provider.id}`);
    }
  };

  const onOpenSportsbook = async (
    provider: ProviderSportResApiInterface,
    game: any,
  ): Promise<void> => {
    if (!userInfo) {
      return router.push(PageUrls.Login);
    }
    try {
      const currencyFiltered = provider.currencies
        .filter((x) => x.includes(currency))
        .at(0);
      window.open(
        await onOpenSportURL(
          provider.objectId,
          currencyFiltered,
          walletInfo?.objectId,
          provider.id,
          game.gameCode,
        ),
        '_blank',
      );
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  const handleOpenSportsbooks = async (
    provider: ProviderSportResApiInterface,
  ) => {
    let game;
    try {
      game = await request({
        url: `/sportsbooks/${provider.id}/games`,
        method: 'GET',
        params: {
          currency,
        },
      });
    } catch (e) {
      HttpErrorHandler(e);
    }
    if (game.length === 1) {
      onOpenSportsbook(provider, game[0]);
    } else {
      router.push(`${PageUrls.Sport}/${provider.id}`);
    }
  };

  const casinoProviderMapped = useMemo(() => {
    if (!casinos) {
      return [];
    }
    return casinos.map((res) => {
      return {
        ...res,
        logo: `${S3_MEDIA_BUCKET}/${res.id}/logo/${res.id}-logo.png`,
        hero: `${S3_BUCKET_IMAGES}/providers/hero-${res.id}.webp`,
        action: () => handleOpenCasino(res),
      };
    });
  }, [casinos, walletInfo, locale]);

  const gamingProviderMapped = useMemo(() => {
    if (!gaming) {
      return [];
    }
    return gaming.map((res) => {
      return {
        ...res,
        logo: `${S3_MEDIA_BUCKET}/${res.id}/logo/${res.id}-logo.png`,
        hero: `${S3_BUCKET_IMAGES}/providers/hero-${res.id}.webp`,
        action: () => router.push(`${PageUrls.Gaming}/${res.id}`),
      };
    });
  }, [gaming]);

  const sportsbookProviderMapped = useMemo(() => {
    if (!sports) {
      return [];
    }
    return sports.map((res) => {
      return {
        ...res,
        logo: `${S3_MEDIA_BUCKET}/${res.id}/logo/${res.id}-logo.png`,
        hero: `${S3_BUCKET_IMAGES}/providers/hero-${res.id}.webp`,
        action: () => handleOpenSportsbooks(res),
      };
    });
  }, [sports, walletInfo, locale]);

  const providers = useMemo(() => {
    if (Object.values(ProviderEnum).includes(menuEnter)) {
      return {
        [ProviderEnum.CASINO]: casinoProviderMapped,
        [ProviderEnum.GAMING]: gamingProviderMapped,
        [ProviderEnum.SPORT]: sportsbookProviderMapped,
      };
    }
    return [];
  }, [menuEnter]);

  const handleMenuEnter = (key: string, subMenu: boolean): void => {
    setMenuEnter(key);
    setSubMenuActive(subMenu);
  };
  const handleMenuLeave = (): void => {
    setMenuEnter('home');
    setSubMenuActive(false);
  };

  return (
    <div className="bg-dark h-[60px] w-full">
      <div className="relative mx-auto flex h-full w-full max-w-[1920px] items-center justify-between gap-x-10 px-10 xl:gap-x-20 xl:px-[100px]">
        <div className="flex w-full items-center justify-around">
          {menuData.map((item, i) => {
            return (
              <button
                className={cn(
                  'relative flex h-[60px] w-full items-center gap-2',
                  'capitalize',
                  menuActive === item.path ? 'text-primary' : 'text-white',
                  'hover:text-primary',
                  item.disabled && 'opacity-10',
                )}
                disabled={item.disabled}
                key={i}
                onClick={() => {
                  item.action ? router.push(item.action) : null;
                  setMenuActive(item.path ?? '');
                  if (
                    [
                      ProviderEnum.CASINO,
                      ProviderEnum.GAMING,
                      ProviderEnum.SPORT,
                    ].includes(item.key as ProviderEnum)
                  ) {
                    setSubMenuActive(!subMenuActive);
                  }
                }}
                onMouseEnter={() => {
                  handleMenuLeave();
                  delay(() => {
                    handleMenuEnter(item.key, item.subMenu);
                  }, 10);
                }}
              >
                <div className="relative ">
                  {item?.isNew ? (
                    <div className="bg-danger absolute -top-1 right-0 translate-x-1/3 rounded p-0.5 text-[8px] leading-none text-white">
                      {t('new')}
                    </div>
                  ) : null}
                  <span className="text-2xl">{item.icon}</span>
                </div>
                <span className="text-lg">{item.title}</span>
              </button>
            );
          })}
        </div>
        <div
          className={cn(
            subMenuActive
              ? 'visible top-full bg-opacity-80'
              : 'invisible top-3/4 opacity-0',
            'bg-dark absolute left-0 min-h-40 w-full py-5 transition-all duration-300',
            'z-10 flex flex-wrap items-center justify-center gap-4 px-10 xl:px-[100px]',
          )}
          onMouseLeave={handleMenuLeave}
        >
          {providers[menuEnter]?.map((item, i) => (
            <ClientSideRender key={i}>
              <ProviderCard
                hero={item.hero}
                key={i}
                logo={item.logo}
                onOpenGame={() => {
                  item.action();
                  handleMenuLeave();
                }}
                theme={item.meta?.theme}
                title={item.title}
              />
            </ClientSideRender>
          ))}
        </div>
        <div
          className={cn(
            'absolute -bottom-8 left-0 z-0 w-full bg-black',
            subMenuActive && 'invisible',
          )}
        />
      </div>
    </div>
  );
};
export default MenuBar;
