import { useMemo } from 'react';
import ProviderCard from '~components/card/provider-card';
import PageUrls from '~constants/page-urls';
import { useAllGames } from '~hooks/use-gaming';
import { useRouter } from '~hooks/use-router';
import {
  DEFAULT_CURRENCY,
  S3_BUCKET_IMAGES,
  S3_MEDIA_BUCKET,
} from '~constants/etc';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';

const GamingProviderListsContainer: React.FC = () => {
  const router = useRouter();
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;
  const { data: gamingProvider } = useAllGames(currency);

  const gamingProviderMapped = useMemo(() => {
    if (!gamingProvider) {
      return [];
    }
    return gamingProvider.map((provider) => {
      return {
        ...provider,
        logo: `${S3_MEDIA_BUCKET}/${provider.id}/logo/${provider.id}-logo.png`,
        hero: `${S3_BUCKET_IMAGES}/providers/hero-${provider.id}.webp`,
      };
    });
  }, [gamingProvider]);

  return (
    <div className="wrapped-content my-4">
      <div className="grid grid-cols-[repeat(auto-fit,_minmax(154px,_1fr))] gap-4">
        {gamingProviderMapped.map((item) => {
          return (
            <ProviderCard
              className="w-full"
              logo={item.logo}
              hero={item.hero}
              key={item.id}
              onOpenGame={() => {
                router.push(`${PageUrls.Gaming}/${item.id}`);
              }}
              theme={item.meta?.theme}
              title={item.title}
            />
          );
        })}
      </div>
    </div>
  );
};

export default GamingProviderListsContainer;
