'use client';

import { cn } from 'class-merge';
import { useState, type FC } from 'react';
import Image from 'next/image';

interface ProviderCardInterface {
  hero?: string;
  logo?: string;
  title?: string;
  theme?: string[];
  className?: string;
  onOpenGame: () => void;
}

const CustomImage: FC<{
  logo: string | undefined;
  hero: string | undefined;
}> = ({ logo, hero }) => {
  const [isHeroError, setIsHeroError] = useState<boolean>(false);
  const [isIconError, setIsIconError] = useState<boolean>(false);

  return (
    <>
      <Image
        alt="logo"
        className={cn('absolute left-2.5 top-7', isIconError && 'hidden')}
        draggable={false}
        height={30}
        onError={() => {
          setIsIconError(true);
        }}
        src={logo || ''}
        unoptimized
        width={30}
      />
      <Image
        alt="hero"
        className={cn(
          'absolute -right-4 bottom-1.5 h-[160px] w-[160px] object-contain',
          isHeroError && 'hidden',
        )}
        draggable={false}
        height={160}
        onError={() => {
          setIsHeroError(true);
        }}
        src={hero || ''}
        unoptimized
        width={160}
      />
    </>
  );
};

const ProviderCard: FC<ProviderCardInterface> = ({
  hero,
  logo,
  title = 'ไม่พบชื่อผู้ให้บริการ',
  theme,
  className,
  onOpenGame,
}) => {
  const color = theme?.at(0) || '#FFD148';

  return (
    <button
      className={cn(
        'relative h-[161px] w-[154px] overflow-hidden pt-5',
        className,
      )}
      onClick={onOpenGame}
    >
      <div
        className="h-[141px] w-full overflow-hidden rounded-lg"
        style={{
          background: `radial-gradient(69.98% 87.64% at 50.56% -25.28%, ${color} 0.29%, #091023 100%)`,
        }}
      />
      <CustomImage hero={hero} logo={logo} />
      <div className="from-layout pointer-events-none absolute inset-x-0 bottom-0 z-0 h-[90px] rounded-b-lg bg-gradient-to-t from-10%" />
      <div className="absolute inset-x-0 bottom-3 truncate px-2.5 text-left text-sm font-bold text-white/80">
        {title}
      </div>
    </button>
  );
};
export default ProviderCard;
