import axios from 'axios';
import { isEmpty } from 'radash';
import type { AxiosRequestConfig } from 'axios';
import { signOut } from 'next-auth/react';
import { destroyCookie, parseCookies } from 'nookies';
import i18n from '~i18n';
import PageUrls from '~constants/page-urls';

export type GetRequest = AxiosRequestConfig | null;

const cookies = parseCookies();
const jwtToken = cookies.access_token || '';

const instance = axios.create({
  baseURL:
    typeof window === 'object'
      ? '/api'
      : `${process.env.NEXT_PUBLIC_API_SERVICE}/api`,
});

instance.interceptors.request.use((config) => {
  if (!isEmpty(jwtToken) && !config.headers.Authorization) {
    config.headers.Authorization = `Bearer ${jwtToken}`;
  }

  if (!config.method || config.method.toLowerCase() === 'get') {
    config.headers['x-agent-name'] = process.env.NEXT_PUBLIC_AGENT_NAME;
  }

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (typeof window === 'object') {
      if (error?.response?.status === 401) {
        const pathname = window.location.pathname;
        if (!/(?:\/\w+)?\/login/i.test(pathname)) {
          destroyCookie(undefined, 'access_token');
          await signOut({ callbackUrl: `/${i18n.language}${PageUrls.Login}` });
        }
      }
    }
    return Promise.reject(error);
  },
);

export const axiosInstance = instance;
