import type { FC } from 'react';
import { useMemo } from 'react';
import { proxied } from 'radash';
import type { GameFeatureListsResApi } from '../../types/provider';
import { useGameFeature } from '~hooks/use-providers';
import {
  DEFAULT_CURRENCY,
  ProviderTypeEnum,
  S3_BUCKET_IMAGES,
} from '~constants/etc';
import ProviderCard from '~components/card/provider-card';
import getImageList from '~libs/get-image-list';
import PageUrls from '~constants/page-urls';
import { useRouter } from '~hooks/use-router';
import { useWalletInfo } from '~hooks/use-wallet';
import { useUserInfo } from '~hooks/use-user';
import { useTranslation } from 'react-i18next';
import { cn } from 'class-merge';
import { ProviderCasinoResApiInterface } from '../../types/casino';
import objectToQueryString from '~libs/object-to-query-string';
import { encrypt } from '~libs/encrypt';
import HttpErrorHandler from '~components/http-error-handler';
import request from '~libs/request';
import { parseCookies } from 'nookies';
import { useCasinos } from '~hooks/use-casinos';

const PopularProviders: FC = () => {
  const router = useRouter();
  const cookies = parseCookies();
  const locale = cookies.NEXT_LOCALE || '';
  const { t } = useTranslation('providers');
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;
  const { data: recommendProvider } = useGameFeature(currency);
  const { data: casinoProviders } = useCasinos(currency);

  const onRequestOpenCasino = (
    provider: ProviderCasinoResApiInterface | undefined,
    game: any,
  ): void => {
    if (!userInfo) {
      return router.push(PageUrls.Login);
    }
    try {
      const currencyFiltered = provider?.currencies
        .filter((x) => x.includes(currency))
        .at(0);
      const queryString = objectToQueryString({
        category: ProviderTypeEnum.Casino,
        providerId: provider?.objectId,
        providerName: provider?.id,
        gameCode: game.gameCode,
        currency: currencyFiltered,
        walletId: walletInfo?.objectId,
      });
      router.push(`/${locale}${PageUrls.OpenGame}?qs=${encrypt(queryString)}`);
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  const handleOpenCasino = async (providerId: string) => {
    const provider = casinoProviders?.find((res) => res.id === providerId);
    let game;
    try {
      game = await request({
        url: `/casinos/${provider?.id}/games`,
        method: 'GET',
        params: {
          currency,
        },
      });
    } catch (e) {
      HttpErrorHandler(e);
    }
    if (game.length === 1) {
      onRequestOpenCasino(provider, game[0]);
    } else {
      router.push(`${PageUrls.Casino}/${provider?.id}`);
    }
  };

  const actionOpenGame = proxied((prop: ProviderTypeEnum) => {
    switch (prop) {
      case ProviderTypeEnum.Casino:
        return (providerId: string) => {
          handleOpenCasino(providerId);
        };
      case ProviderTypeEnum.Gaming:
        return (providerId: string) =>
          router.push(`${PageUrls.Gaming}/${providerId}`);
      default:
        throw new Error('The category you are looking for cannot be found.');
    }
  });

  const getRecommendProvider = (
    type: ProviderTypeEnum,
    data: GameFeatureListsResApi[] | undefined,
  ): any => {
    const providerFiltered =
      data?.filter(
        (item) =>
          type === item.category &&
          item.badges.includes('popular') &&
          !item.gameCode,
      ) || [];

    return providerFiltered.map((provider) => {
      const images = getImageList(provider.images);
      return {
        ...provider,
        logo: images?.logo,
        hero: `${S3_BUCKET_IMAGES}/providers/hero-${provider.providerId}.webp`,
        action: () => {
          actionOpenGame[type](provider.providerId);
        },
      };
    });
  };

  const recommendProviderMapped = useMemo(() => {
    const recommendCasinoProvider = getRecommendProvider(
      ProviderTypeEnum.Casino,
      recommendProvider,
    );
    const recommendGamingProvider = getRecommendProvider(
      ProviderTypeEnum.Gaming,
      recommendProvider,
    );
    return [
      {
        label: t('hot-casino-provider'),
        providers: recommendCasinoProvider,
        visible: Boolean(recommendCasinoProvider?.length),
      },
      {
        label: t('hot-gaming-provider'),
        providers: recommendGamingProvider,
        visible: Boolean(recommendGamingProvider?.length),
      },
    ];
  }, [recommendProvider]);

  return (
    <div className="wrapped-content my-4 flex flex-col gap-2 xl:my-6 xl:gap-4">
      {recommendProviderMapped.map((res, i) => (
        <div className={cn('flex flex-col', !res.visible && 'hidden')} key={i}>
          <h1 className="md:text-2xl">{res.label}</h1>
          <div className="flex w-full flex-nowrap overflow-hidden">
            <div className="grid w-full grid-cols-2 items-center justify-center gap-4 sm:flex sm:flex-wrap sm:items-start sm:justify-start">
              {res.providers?.map((provider, index) => (
                <ProviderCard
                  logo={provider.logo}
                  hero={provider.hero}
                  key={index}
                  onOpenGame={provider.action}
                  theme={provider.meta?.theme}
                  title={provider.providerName}
                  className="w-full sm:w-[154px]"
                />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PopularProviders;
