'use client';

import {
  MegaphoneOutlined,
  ChangePasswordOutlined,
  ExceptionOutlined,
  ProfileFilled,
  SanctuaryOutlined,
  BetTransactionOutlined,
  LogoutOutlined,
  WalletOutlined,
  CreditCardOutlined,
} from '@packages/icons-react';
import { useTranslation } from 'react-i18next';
import { destroyCookie } from 'nookies';
import { signOut } from 'next-auth/react';
import DynamicMenu from '~components/widgets/dynamic-menu';
import { LanguageEnum } from '~constants/etc';
import PageUrls from '~constants/page-urls';
import { useRouter } from '~hooks/use-router';
import useIsMobile from '~libs/use-is-mobile';
import { Button } from '~components/button';
import { ButtonColors } from '~constants/components';
import { useDialog } from '~hooks/use-dialog';

const UserMenu: React.FC = () => {
  const router = useRouter();
  const isMobile = useIsMobile();
  const { i18n, t } = useTranslation([
    'common',
    'navigation',
    'etc',
    'buttons',
  ]);
  const language = i18n.language || LanguageEnum.VI;
  const [dialog, dialogContext] = useDialog();

  const handleLogout = (): void => {
    dialog.content({
      maskable: true,
      size: 'xxl',
      children: (
        <div className="bg-dark w-full rounded-lg p-5 sm:w-96">
          <div className="bg-evaluated mx-auto mt-3 flex size-10 items-center justify-center rounded-lg">
            <LogoutOutlined className="text-danger text-2xl" />
          </div>

          <div className="mt-5 flex flex-col gap-1.5 text-center">
            <p className="text-base font-bold">{t('etc:logout-description')}</p>
            <p className="text-gray text-sm">{t('etc:confirm-logout')}</p>
          </div>

          <div className="mt-7 flex flex-col gap-2.5">
            <Button
              color={ButtonColors.Danger}
              onClick={async () => {
                destroyCookie(null, 'access_token', { path: '/' });
                await signOut({ callbackUrl: PageUrls.Home });
              }}
            >
              {t('common:logout')}
            </Button>
            <Button
              color={ButtonColors.Transparent}
              onClick={() => {
                dialog.destroy();
              }}
            >
              {t('buttons:btn-cancel')}
            </Button>
          </div>
        </div>
      ),
      onClose: () => {
        dialog.destroy();
      },
    });
  };

  const menuData = [
    {
      hidden: !isMobile,
      name: t('common:deposit'),
      icon: <WalletOutlined />,
      action: PageUrls.FinancialDeposit,
    },
    {
      hidden: !isMobile,
      name: t('common:withdraw'),
      icon: <CreditCardOutlined />,
      action: PageUrls.FinancialWithdraw,
    },
    {
      name: t('common:bet-transactions'),
      icon: <BetTransactionOutlined />,
      action: PageUrls.BetTransactionCasino,
    },
    {
      name: t('common:profile'),
      icon: <ProfileFilled />,
      action: PageUrls.Account,
    },
    {
      name: t('common:bank-account'),
      icon: <SanctuaryOutlined />,
      action: PageUrls.PaymentMethods,
    },
    {
      name: t('common:transaction-report'),
      icon: <ExceptionOutlined />,
      action: PageUrls.FinancialTransaction,
    },
    {
      name: t('navigation:change-password-description'),
      icon: <ChangePasswordOutlined />,
      action: PageUrls.ChangePassword,
    },
    {
      name: t('common:menu.announce'),
      icon: <MegaphoneOutlined />,
      action: PageUrls.Announcement,
    },
    {
      name: t('common:logout'),
      icon: <LogoutOutlined />,
      action: handleLogout,
    },
    {
      hidden: !isMobile,
      name: t('common:language'),
      icon: `/images/svg/flags/${language}.svg`,
      action: LanguageEnum.VI,
      children: [
        {
          name: 'Tiếng Việt',
          icon: '/images/svg/flags/vi.svg',
          action: LanguageEnum.VI,
        },
        {
          name: 'English',
          icon: '/images/svg/flags/en.svg',
          action: LanguageEnum.EN,
        },
      ],
    },
  ];

  const handleChangeMenu = async (action: string): Promise<void> => {
    if (action === LanguageEnum.VI || action === LanguageEnum.EN) {
      await onChangeLang(action);
    } else {
      router.push(action);
    }
  };

  const onChangeLang = async (langCode: string): Promise<void> => {
    await i18n.changeLanguage(langCode);
    const currentPathname = window.location.pathname;
    const newPathname = currentPathname.replace(
      /^\/[a-z]{2}(\/|$)/,
      `/${langCode}$1`,
    );
    router.push(newPathname, { scroll: false });
  };

  return (
    <>
      {dialogContext}
      <DynamicMenu
        className="h-[40px] w-[40px]"
        isArrow={false}
        itemActive=""
        items={menuData}
        onChange={handleChangeMenu}
      />
    </>
  );
};

export default UserMenu;
