import { motion } from 'framer-motion';
import { FC, useMemo, useRef, useState } from 'react';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import useIsMobile from '~libs/use-is-mobile';
import { useCasinos } from '~hooks/use-casinos';
import { useAllGames } from '~hooks/use-gaming';
import { useSportsbook } from '~hooks/use-sports-book';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';
import {
  DEFAULT_CURRENCY,
  S3_BUCKET_IMAGES,
  S3_MEDIA_BUCKET,
} from '~constants/etc';
import { ClientSideRender } from '~components/layouts/client-side-render';

const CustomImage: FC<{
  src: string;
}> = ({ src }) => {
  const [image, setImage] = useState<string>(src);

  return (
    <div className="flex flex-col gap-2">
      <div className="bg-dark relative flex h-[65px] w-[160px] items-center justify-center rounded-lg">
        <Image
          alt="logo"
          height={72}
          src={image}
          width={80}
          onError={() => setImage(`${S3_BUCKET_IMAGES}/logo.webp`)}
        />
      </div>
    </div>
  );
};

const OurServiceProviders: React.FC = () => {
  const { t } = useTranslation(['home']);
  const isMobile = useIsMobile();
  const containerRef = useRef(null);
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;
  const { data: casinoProvider } = useCasinos(currency);
  const { data: gamingProvider } = useAllGames(currency);
  const { data: sportsbookProvider } = useSportsbook(currency);

  const getImageProvider = (providers) => {
    return (
      providers?.map((provider) => ({
        horizontalLogo: `${S3_MEDIA_BUCKET}/${provider.id}/logo/${provider.id}-horizontal-logo.png`,
      })) || []
    );
  };

  const allProviders = useMemo(() => {
    return [
      ...getImageProvider(casinoProvider),
      ...getImageProvider(gamingProvider),
      ...getImageProvider(sportsbookProvider),
    ];
  }, [casinoProvider, gamingProvider, sportsbookProvider]);

  return (
    <div className="wrapped-content my-6 flex w-full flex-col gap-4 xl:my-10">
      <h1 className="mt-6 md:text-2xl">{t('home:providers.title')}</h1>
      <motion.div
        className="flex cursor-pointer flex-nowrap overflow-hidden md:block md:cursor-default"
        ref={containerRef}
      >
        <motion.div
          className="flex gap-4 md:grid md:grid-cols-[repeat(auto-fit,_minmax(166px,_1fr))]"
          drag="x"
          dragConstraints={containerRef}
          dragListener={isMobile}
        >
          {allProviders.map((item, i) => (
            <ClientSideRender>
              <CustomImage src={item.horizontalLogo} key={i} />
            </ClientSideRender>
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default OurServiceProviders;
