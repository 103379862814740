const PageUrls = {
  Home: '/',
  Login: '/login',
  Register: '/register',
  ForgotPassword: '/forgot-password',
  Affiliate: '/affiliate',
  EnableAffiliate: '/affiliate/enable-affiliate',
  AffiliateRevenue: '/affiliate/revenue',
  AffiliateMember: '/affiliate/member',
  StatisticsAffiliate: '/affiliate/statistics',
  Bonus: '/bonus',
  Profile: '/profile',
  Contact: '/contact',
  Account: '/account',
  PaymentMethods: '/payment-methods',
  FinancialTransaction: '/financial-transaction',
  ChangePassword: '/change-password',
  FinancialDeposit: '/deposit',
  FinancialDepositPromotion: '/deposit/promotion',
  FinancialWithdraw: '/withdraw',
  BetTransactionCasino: '/bet-transactions/casino',
  BetTransactionGaming: '/bet-transactions/gaming',
  BetTransactionSportsbook: '/bet-transactions/sportsbook',
  Announcement: '/announcement',
  OpenGame: '/open-game',
  Casino: '/casino',
  Gaming: '/gaming',
  Sport: '/sportsbook',
  OldVersion: '/old-version',
};

export default PageUrls;
