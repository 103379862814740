import { parseCookies } from 'nookies';
import { useMemo } from 'react';
import type { ProviderCasinoResApiInterface } from 'types/casino';
import ProviderCard from '~components/card/provider-card';
import HttpErrorHandler from '~components/http-error-handler';
import {
  DEFAULT_CURRENCY,
  ProviderTypeEnum,
  S3_BUCKET_IMAGES,
  S3_MEDIA_BUCKET,
} from '~constants/etc';
import PageUrls from '~constants/page-urls';
import { useCasinos } from '~hooks/use-casinos';
import { useRouter } from '~hooks/use-router';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';
import { encrypt } from '~libs/encrypt';
import objectToQueryString from '~libs/object-to-query-string';
import request from '~libs/request';

const CasinoProviderListsContainer: React.FC = () => {
  const router = useRouter();
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  const cookies = parseCookies();
  const locale = cookies.NEXT_LOCALE || '';
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;
  const { data: casinoProvider } = useCasinos(currency);

  const casinoProviderMapped = useMemo(() => {
    if (!casinoProvider) {
      return [];
    }
    return casinoProvider.map((provider) => {
      return {
        ...provider,
        logo: `${S3_MEDIA_BUCKET}/${provider.id}/logo/${provider.id}-logo.png`,
        hero: `${S3_BUCKET_IMAGES}/providers/hero-${provider.id}.webp`,
      };
    });
  }, [casinoProvider]);

  const onRequestOpenCasino = (
    provider: ProviderCasinoResApiInterface,
    game: any,
  ): void => {
    if (!userInfo) {
      return router.push(PageUrls.Login);
    }
    try {
      const currencyFiltered = provider.currencies
        .filter((x) => x.includes(currency))
        .at(0);
      const queryString = objectToQueryString({
        category: ProviderTypeEnum.Casino,
        providerId: provider.objectId,
        providerName: provider.id,
        gameCode: game.gameCode,
        currency: currencyFiltered,
        walletId: walletInfo?.objectId,
      });
      router.push(`/${locale}${PageUrls.OpenGame}?qs=${encrypt(queryString)}`);
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  const handleOpenCasino = async (provider: ProviderCasinoResApiInterface) => {
    let game;
    try {
      game = await request({
        url: `/casinos/${provider.id}/games`,
        method: 'GET',
        params: {
          currency,
        },
      });
    } catch (e) {
      HttpErrorHandler(e);
    }
    if (game.length === 1) {
      onRequestOpenCasino(provider, game[0]);
    } else {
      router.push(`${PageUrls.Casino}/${provider.id}`);
    }
  };

  return (
    <div className="wrapped-content my-4">
      <div className="grid grid-cols-[repeat(auto-fit,_minmax(154px,_1fr))] gap-4">
        {casinoProviderMapped.map((item) => (
          <ProviderCard
            className="w-full"
            logo={item.logo}
            hero={item.hero}
            key={item.id}
            onOpenGame={() => {
              handleOpenCasino(item);
            }}
            theme={item.meta?.theme}
            title={item.title}
          />
        ))}
      </div>
    </div>
  );
};

export default CasinoProviderListsContainer;
