'use client';

import { FC, useMemo } from 'react';
import type { ProviderSportResApiInterface } from 'types/sport';
import ProviderCard from '~components/card/provider-card';
import HttpErrorHandler from '~components/http-error-handler';
import {
  DEFAULT_CURRENCY,
  S3_BUCKET_IMAGES,
  S3_MEDIA_BUCKET,
} from '~constants/etc';
import { useSportsbook } from '~hooks/use-sports-book';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';
import { onOpenSportURL } from '~libs/open-url';
import PageUrls from '~constants/page-urls';
import request from '~libs/request';
import { useRouter } from '~hooks/use-router';

const SportsbookProviderListsContainer: FC = () => {
  const router = useRouter();
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;
  const { data: sportProvider } = useSportsbook(currency);

  const sportProviderMapped = useMemo(() => {
    if (!sportProvider) {
      return [];
    }
    return sportProvider.map((provider) => ({
      ...provider,
      logo: `${S3_MEDIA_BUCKET}/${provider.id}/logo/${provider.id}-logo.png`,
      hero: `${S3_BUCKET_IMAGES}/providers/hero-${provider.id}.webp`,
    }));
  }, [sportProvider]);

  const onOpenSportsbook = async (
    provider: ProviderSportResApiInterface,
    game: any,
  ): Promise<void> => {
    if (!userInfo) {
      return router.push(PageUrls.Login);
    }
    try {
      const currencyFiltered = provider.currencies
        .filter((x) => x.includes(currency))
        .at(0);
      window.open(
        await onOpenSportURL(
          provider.objectId,
          currencyFiltered,
          walletInfo?.objectId,
          provider.id,
          game.gameCode,
        ),
        '_blank',
      );
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  const handleOpenSportsbooks = async (
    provider: ProviderSportResApiInterface,
  ) => {
    let game;
    try {
      game = await request({
        url: `/sportsbooks/${provider.id}/games`,
        method: 'GET',
        params: {
          currency,
        },
      });
    } catch (e) {
      HttpErrorHandler(e);
    }
    if (game.length === 1) {
      onOpenSportsbook(provider, game[0]);
    } else {
      router.push(`${PageUrls.Sport}/${provider.id}`);
    }
  };

  return (
    <div className="wrapped-content my-4">
      <div className="grid grid-cols-2 gap-4">
        {sportProviderMapped.map((item) => (
          <ProviderCard
            className="w-full"
            logo={item.logo}
            hero={item.hero}
            key={item.id}
            onOpenGame={async () => {
              await handleOpenSportsbooks(item);
            }}
            theme={item.meta?.theme}
            title={item.title}
          />
        ))}
      </div>
    </div>
  );
};

export default SportsbookProviderListsContainer;
