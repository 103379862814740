import type { FC } from 'react';
import { useMemo, useRef } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import type { ProviderSportResApiInterface } from 'types/sport';
import Image from 'next/image';
import { Button } from '~components/button';
import { useSportsbook } from '~hooks/use-sports-book';
import { ButtonColors, SizeTypes } from '~constants/components';
import {
  DEFAULT_CURRENCY,
  S3_BUCKET_IMAGES,
  S3_MEDIA_BUCKET,
} from '~constants/etc';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';
import PageUrls from '~constants/page-urls';
import { useRouter } from '~hooks/use-router';
import { onOpenSportURL } from '~libs/open-url';
import HttpErrorHandler from '~components/http-error-handler';
import request from '~libs/request';

const SportbookProvider: FC = () => {
  const router = useRouter();
  const { t } = useTranslation(['common']);
  const containerRef = useRef(null);
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;
  const { data: sportProvider } = useSportsbook(currency);

  const sportMapped = useMemo(() => {
    if (!sportProvider) {
      return [];
    }
    return sportProvider.map((provider) => {
      return {
        ...provider,
        horizontalLogo: `${S3_MEDIA_BUCKET}/${provider.id}/logo/${provider.id}-horizontal-logo.png`,
        hero: `${S3_BUCKET_IMAGES}/recommend-hero-${provider.id}.webp`,
        background: `${S3_BUCKET_IMAGES}/recommend-background-${provider.id}.webp`,
      };
    });
  }, [sportProvider]);

  const onOpenSportsbook = async (
    provider: ProviderSportResApiInterface,
    game: any,
  ): Promise<void> => {
    if (!userInfo) {
      return router.push(PageUrls.Login);
    }
    try {
      const currencyFiltered = provider.currencies
        .filter((x) => x.includes(currency))
        .at(0);
      window.open(
        await onOpenSportURL(
          provider.objectId,
          currencyFiltered,
          walletInfo?.objectId,
          provider.id,
          game.gameCode,
        ),
        '_blank',
      );
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  const handleOpenSportsbooks = async (
    provider: ProviderSportResApiInterface,
  ) => {
    let game;
    try {
      game = await request({
        url: `/sportsbooks/${provider.id}/games`,
        method: 'GET',
        params: {
          currency,
        },
      });
    } catch (e) {
      HttpErrorHandler(e);
    }
    if (game.length === 1) {
      onOpenSportsbook(provider, game[0]);
    } else {
      router.push(`${PageUrls.Sport}/${provider.id}`);
    }
  };

  return (
    <div className="wrapped-content my-6 flex flex-col gap-4">
      <h1 className="md:text-2xl">{t('all-products-sports')}</h1>
      <motion.div
        className="flex flex-nowrap overflow-hidden"
        ref={containerRef}
      >
        <motion.div
          className="flex gap-4"
          drag="x"
          dragConstraints={containerRef}
        >
          {sportMapped?.map((item) => (
            <div
              className="relative min-h-[200px] w-[360px] overflow-hidden rounded-lg"
              key={item.id}
            >
              <Image
                alt="horizontalLogo"
                className="absolute left-6 top-6 h-[50px] w-[125px]"
                draggable={false}
                height={50}
                src={item.horizontalLogo || ''}
                width={125}
              />
              <Image
                alt="hero"
                className="absolute inset-y-0 right-8 h-[200px] w-[183px]"
                draggable={false}
                height={200}
                src={item.hero}
                width={183}
              />
              <Image
                alt="background"
                className="absolute inset-0 -z-30 h-full w-full object-cover"
                draggable={false}
                height={150}
                src={item.background}
                width={150}
              />
              <Image
                alt="sport-overlay"
                className="absolute inset-0 -left-10 -z-20 h-full w-full object-contain"
                draggable={false}
                height={150}
                quality={100}
                src={`${S3_BUCKET_IMAGES}/sport-overlay.webp`}
                width={150}
              />

              <Button
                className="bg- absolute bottom-4 left-6 z-[9] w-[112px] border border-white"
                color={ButtonColors.Transparent}
                onClick={() => {
                  handleOpenSportsbooks(item);
                }}
                size={SizeTypes.Small}
              >
                {t('common:bet-now')}
              </Button>
            </div>
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default SportbookProvider;
