import type {
  GameInProviderGamingResApi,
  ProviderGamingListResApi,
  ResponseApiGamingBetTransactionInterface,
} from 'types/gaming';
import type { KeyedMutator } from 'swr';
import type { AxiosResponse, Method } from 'axios';
import { useCallback } from 'react';
import { objectify } from 'radash';
import type { Config } from './use-request';
import useRequest from './use-request';
import { S3_MEDIA_BUCKET } from '~constants/etc';

export const useGameListInGamingProvider = (
  provider?: string,
  currency?: string,
  config?: Config<GameInProviderGamingResApi[]>,
): {
  data: GameInProviderGamingResApi[] | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<AxiosResponse<GameInProviderGamingResApi[]>>;
} => {
  const { data, ...rest } = useRequest<GameInProviderGamingResApi[]>(
    provider
      ? {
          method: 'GET' as Method,
          url: `/gaming/${provider}/games`,
          params: {
            currency,
          },
        }
      : null,
    config,
  );

  const withThumbnailPath = (
    games: GameInProviderGamingResApi[] | undefined,
  ) => {
    return (
      games?.map((game) => ({
        ...game,
        thumbnail: `${S3_MEDIA_BUCKET}/${game.provider}/games/${game.gameCode}/thumbnail.webp`,
      })) || []
    );
  };

  return {
    ...rest,
    data: withThumbnailPath(data),
  };
};

export const useAllGames = (
  currency?: string,
  config?: Config<ProviderGamingListResApi[]>,
) => {
  return useRequest<ProviderGamingListResApi[]>(
    {
      url: '/gaming',
      params: {
        currency,
      },
    },
    {
      revalidateOnFocus: true,
      ...config,
    },
  );
};

export const useGamingBetTransactions = (
  query: any = {},
  config?: Config<ResponseApiGamingBetTransactionInterface>,
) => {
  const provider = query.provider ? { provider: query.provider } : null;

  return useRequest<ResponseApiGamingBetTransactionInterface>(
    {
      url: '/gaming/bet-transactions',
      params: {
        limit: 100,
        startAt: query.startAt,
        endAt: query.endAt,
        ...provider,
      },
    },
    { ...config, revalidateOnFocus: true },
  );
};

export const useGamingInfoHelper = (currency: string) => {
  const { data } = useAllGames(currency);
  const getGamingProviderImage = useCallback(
    (provider: string) => {
      const gamingProvider = data?.find((i) => i.id === provider);
      if (!gamingProvider) {
        return;
      }
      return objectify(
        gamingProvider?.images,
        (f) => f.name as string,
        (f) => f.value as string,
      );
    },
    [data],
  );
  return {
    getGamingProviderImage,
  };
};
