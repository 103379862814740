import type {
  ProviderSportResApiInterface,
  ResponseApiSportBetTransactionInterface,
} from 'types/sport';
import { useCallback } from 'react';
import { objectify } from 'radash';
import type { Config } from './use-request';
import useRequest from './use-request';
import type { KeyedMutator } from 'swr';
import type { AxiosResponse, Method } from 'axios';
import type { GameInProviderGamingResApi } from '../types/gaming';
import { S3_MEDIA_BUCKET } from '~constants/etc';

export const useSportsbook = (
  currency?: string,
  config?: Config<ProviderSportResApiInterface[]>,
) => {
  return useRequest<ProviderSportResApiInterface[]>(
    {
      url: '/sportsbooks',
      params: {
        currency,
      },
    },
    {
      revalidateOnFocus: true,
      ...config,
    },
  );
};

export const useGameListInSportProvider = (
  provider?: string,
  currency?: string,
  config?: Config<any[]>,
): {
  data: any[] | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<AxiosResponse<any[]>>;
} => {
  const { data, ...rest } = useRequest<any[]>(
    provider
      ? {
          method: 'GET' as Method,
          url: `/sportsbooks/${provider}/games`,
          params: {
            currency,
          },
        }
      : null,
    config,
  );

  const withThumbnailPath = (
    games: GameInProviderGamingResApi[] | undefined,
  ) => {
    return (
      games?.map((game) => ({
        ...game,
        thumbnail: `${S3_MEDIA_BUCKET}/${game.provider}/games/${game.gameCode}/thumbnail.webp`,
      })) || []
    );
  };

  return {
    ...rest,
    data: withThumbnailPath(data),
  };
};

export const useSportsbookInfoHelper = (currency: string) => {
  const { data } = useSportsbook(currency);
  const getSportProviderImage = useCallback(
    (provider: string) => {
      const sportProvider = data?.find((i) => i.id === provider);
      if (!sportProvider) {
        return;
      }
      return objectify(
        sportProvider?.images,
        (f) => f.name as string,
        (f) => f.value as string,
      );
    },
    [data],
  );
  return {
    getSportProviderImage,
  };
};

export const useSportBetTransactions = (
  query: any = {},
  config?: Config<ResponseApiSportBetTransactionInterface>,
) => {
  return useRequest<ResponseApiSportBetTransactionInterface>(
    {
      url: '/sportsbooks/bet-transactions',
      params: {
        startAt: query.startAt,
        endAt: query.endAt,
        provider: query.provider,
      },
    },
    { ...config, revalidateOnFocus: true },
  );
};
